<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <div
      :style="style"
    >
      <output class="countdown hours">
        {{ hours }}
      </output>
      :
      <output class="countdown minutes">
        {{ minutes }}
      </output>
      :
      <output class="countdown seconds">
        {{ seconds }}
      </output>
    </div>
    <template v-if="showButton">
      <div style="width: 100%; text-align: center">
        <v-btn
          :class="`${classes}`"
          :disabled="disabled"
          type="button"
          data-content-button-action="countdown_done"
          :title="lang('ChecklistBuilder.titles.countdown_done')"
          small
          @click="onClick"
        >
          <v-icon>
            mdi-timer-sand-empty
          </v-icon>
          {{ buttonLabel }}
        </v-btn>
      </div>
    </template>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeCountdown',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      timer: false
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    style() {
      const settings = this.cell.settings
      let style = 'width: 100%;'
      if (settings.font_size) {
        style += ` font-size: ${settings.font_size}px;`
      }
      if (settings.font_bold) {
        style += ` font-weight: ${settings.font_bold};`
      }
      if (settings.font_italic) {
        style += ` font-style: ${settings.font_italic};`
      }
      if (settings.countdown_hide) {
        style += ` display: none;`
      }
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style += `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = parseInt(builder.getCellValue(index, this.cell, this.clone, this.totalTime)) || 0
      return value
    },
    hours() {
      return this.padTime(parseInt(this.value / 3600) || 0)
    },
    minutes() {
      return this.padTime((parseInt(this.value / 60) || 0) % 60)
    },
    seconds() {
      return this.padTime((this.value % 60) || 0)
    },
    totalTime() {
      const settings = this.cell.settings || {}
      let totalTime = 0
      totalTime += parseInt(settings.countdown_seconds) || 0
      totalTime += (parseInt(settings.countdown_minutes) || 0) * 60
      totalTime += (parseInt(settings.countdown_hours) || 0) * 3600
      return totalTime
    },
    inactive() {
      if (this.disabled) {
        return true
      }
      if (this.dataViewer.done) {
        return true
      }
      if (/^(0|clicked)$/gi.test(this.value)) {
        return true
      }
      return false
    },
    showButton() {
      return this.cell.settings.countdown_done
    },
    buttonLabel() {
      const label = this.cell.settings.countdown_done_label
      if (!label) {
        return ''
      }
      return label
    }
  },
  updated() {
    if (this.inactive) {
      this.stopTimer()
      return
    }
    if (!this.timer) {
      this.startTimer()
    }
  },
  created() {
    const settings = this.cell.settings || {}
    const lockAction = parseInt(settings.countdown_expired)
    const done = this.dataViewer.done
    if (lockAction === 1) { // Ent-/sperren der Kollektion
      if (done) {
        this.data.disabled = true
      } else {
        this.data.disabled = false
      }
    } else if (lockAction === 2) { // Ent-/sperren der Checkliste
      const index = this.checklist.index
      const status = this.data.status[index]
      if (done) {
        status.editable = false
      } else {
        status.editable = true
      }
    }
    if (this.inactive) {
      return
    }
    if (!this.timer) {
      this.startTimer()
    }
  },
  methods: {
    padTime(value) {
      return value > 9 ? value : '0' + value
    },
    onClick() {
      if (this.inactive) {
        return
      }
      this.stopTimer()
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = builder.getCellValue(index, this.cell, this.clone, this.totalTime)
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: 'clicked',
        previousValue: previousValue
      })
    },
    startTimer() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      this.timer = setInterval(() => {
        if (this.inactive) {
          this.stopTimer()
          return
        }
        const previousValue = builder.getCellValue(index, this.cell, this.clone, this.totalTime)
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: this.value - 1,
          previousValue: previousValue
        })
      }, 1000)
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = false
      }
    }
  }
}
</script>

<style>
  .checklist-viewer .element-content .countdown {
    display: inline-block;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    text-align: inherit;
    vertical-align: inherit;
  }

  .checklist-viewer .element-content.cell_type_countdown
  button i {
    font-size: 16px;
    padding-right: 5px;
  }
</style>
